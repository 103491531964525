<script>
import EventForm from "@/views/menu/event/eventForm";
import Component, { mixins } from "vue-class-component";
import { toastService } from "@/services/toastService.js";

@Component({
  inject: {
    eventService: "eventService",
    fileUploadService: "fileUploadService"
  }
})
export default class EventEdit extends mixins(EventForm) {
  formId = "edit-event-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        disabled: () => {
          return this.fileUploadService.isUploading();
        },
        handle: this.save
      }
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
        await this.eventService.update(this.model.id, this.model);
      this.$router.push("/event/list");
      toastService.success(this.translations.success.event_update);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle () {
    return this.translations.pageTitles.events_edit;
  }

  async created () {
    this.model = await this.eventService.read(this.$route.params.id);
    this.model.languageIds = [];
    for (let i in this.model.languages) {
      const { id, isDefault } = this.model.languages[i];
      if (isDefault) {
        this.model.defaultLanguageId = id;
      }
      this.model.languageIds.push(id);
    }
    this.isReady = true;
  }
}
</script>
